import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  position: relative;
  padding: 0 100px 300px;
  background: linear-gradient(-90deg, #a1c4fd 0%, #c2e9fb 100%);
  @media (max-width: 890px) {
    height: initial;
    padding: 90px 8vw 340px;
  }
`

export const Content = styled.div`
  padding-top: 120px;
`

export const Title = styled.h1`
  font-size: 32px;
  padding: 40px;
  text-align: center;
`

export const LogoWrapper = styled(Link)`
  position: absolute;
  top: 50px;
  left: 100px;
  display: block;
  @media (max-width: 890px) {
    display: none;
  }
  ${(props) =>
    props.mobile &&
    css`
      display: none;
      @media (max-width: 890px) {
        top: 24px;
        left: 24px;
        display: block;
      }
    `}
`

export const ContactButton = styled.div`
  position: absolute;
  top: 58px;
  right: 100px;
  @media (max-width: 890px) {
    position: initial;
  }
`

export const H2 = styled.h2`
  margin-top: 30px;
  margin-bottom: 10px;
`

export const P = styled.p`
  margin: 16px 0;
`
