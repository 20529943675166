import React from "react"
import { Container, Title, LogoWrapper, Content, H2, P } from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const PrivacyNotice = () => {
  return (
    <Container>
      <LogoWrapper to="/">
        <StaticImage
          src="../../images/oak-games-logo.png"
          alt="human"
          placeholder="blurred"
          quality="100"
        />
      </LogoWrapper>
      <LogoWrapper to="/" mobile={+true}>
        <StaticImage
          src="../../images/oak-games-logo-mobile.png"
          alt="human"
          placeholder="blurred"
          quality="100"
        />
      </LogoWrapper>
      <Content>
        <Title>Oak Games Privacy Policy</Title>
        <div>
          <H2>PRIVACY NOTICE</H2>
          <h4>Last updated September 22, 2022</h4>
          <P>
            This privacy notice for Oakfusion ('Company', 'we', 'us', or
            'our',), describes how and why we might collect, store, use, and/or
            share ('process') your information when you use our services
            ('Services'), such as when you:
          </P>
          <ul>
            <li>
              Download and use our mobile application (Alien Defence), or any
              other application of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <h4>Questions or concerns?</h4>
          <P>
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at pwywijas@oakgames.it.
          </P>
          <H2>SUMMARY OF KEY POINTS</H2>
          <P>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for. You can also click
            here to go directly to our table of contents.
          </P>
          <P>
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with Oakfusion and the Services, the choices you
            make, and the products and features you use. Click here to learn
            more.
          </P>
          <P>
            Do we process any sensitive personal information? We do not process
            sensitive personal information.
          </P>
          <P>
            Do we receive any information from third parties? We do not receive
            any information from third parties.
          </P>
          <P>
            How do we process your information? We process your information to
            provide, improve, and administer our Services, communicate with you,
            for security and fraud prevention, and to comply with law. We may
            also process your information for other purposes with your consent.
            We process your information only when we have a valid legal reason
            to do so. Click here to learn more.
          </P>
          <P>
            In what situations and with which parties do we share personal
            information? We may share information in specific situations and
            with specific third parties. Click here to learn more.
          </P>
          <P>
            What are your rights? Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information. Click here to learn
            more.
          </P>
          <P>
            How do you exercise your rights? The easiest way to exercise your
            rights is by filling out our data subject request form available{" "}
            <a href="https://app.termly.io/notify/816eb576-d506-4352-8336-c36c1ee6873d">
              here
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </P>
          <P>
            Want to learn more about what Oakfusion does with any information we
            collect? Click here to review the notice in full.
          </P>
          <H2>TABLE OF CONTENTS</H2>
          <ol>
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
            <li>
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </li>
            <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
            <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </li>
          </ol>
          <H2>WHAT INFORMATION DO WE COLLECT?</H2>
          <h3>Personal information you disclose to us</h3>
          <P>
            In Short: We collect personal information that you provide to us.
          </P>
          <P>
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.
          </P>
          <h3>
            Sensitive Information. We do not process sensitive information.
          </h3>
          <P>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </P>
          <H2>HOW DO WE PROCESS YOUR INFORMATION?</H2>
          <P>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </P>
          <P>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </P>
          <P>
             To save or protect an individual's vital interest. We may process
            your information when necessary to save or protect an individual’s
            vital interest, such as to prevent harm.
          </P>
          <H2>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</H2>
          <P>
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e. legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfil our
            contractual obligations, to protect your rights, or to fulfil our
            legitimate business interests.
          </P>
          <h3>
            If you are located in the EU or UK, this section applies to you.
          </h3>
          <P>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </P>
          <ul>
            <li>
              Consent. We may process your information if you have given us
              permission (i.e. consent) to use your personal information for a
              specific purpose. You can withdraw your consent at any time. Click
              here to learn more.
            </li>
            <li>
              Legal Obligations. We may process your information where we
              believe it is necessary for compliance with our legal obligations,
              such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your
              information as evidence in litigation in which we are involved.
            </li>
            <li>
              Vital Interests. We may process your information where we believe
              it is necessary to protect your vital interests or the vital
              interests of a third party, such as situations involving potential
              threats to the safety of any person.
            </li>
          </ul>
          <h3>If you are located in Canada, this section applies to you.</h3>
          <P>
            We may process your information if you have given us specific
            permission (i.e. express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e. implied consent). You can withdraw your consent at
            any time. Click{" "}
            <a href="C:\Users\Oakfusion\Desktop\index.html#withdrawconsent">
              here
            </a>{" "}
            to learn more.
          </P>
          <P>
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </P>
          <ul>
            <li>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
            <li>For investigations and fraud detection and prevention</li>
            <li>
              For business transactions provided certain conditions are met
            </li>
            <li>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
            <li>
              If we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </li>
            <li>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province
            </li>
            <li>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
            <li>
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
            <li>
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
            <li>
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>
          <H2>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</H2>
          <P>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </P>
          <P>
            We may need to share your personal information in the following
            situations:
          </P>
          <ul>
            <li>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
          </ul>
          <H2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</H2>
          <P>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
          </P>
          <P>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </P>
          <H2>HOW LONG DO WE KEEP YOUR INFORMATION?</H2>
          <P>
            In Short: We keep your information for as long as necessary to
            fulfil the purposes outlined in this privacy notice unless otherwise
            required by law.
          </P>
          <P>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </P>
          <P>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymise such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </P>
          <H2>WHAT ARE YOUR PRIVACY RIGHTS?</H2>
          <P>
            In Short: In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), and Canada, you have rights that allow you
            greater access to and control over your personal information. You
            may review, change, or terminate your account at any time.
          </P>
          <P>
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section 'HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?' below.
          </P>
          <P>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </P>
          <P>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </P>
          <P>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </P>
          <P>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
          </P>
          <P>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </P>
          <P>
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. To opt out of
            interest-based advertising by advertisers on our Services visit
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            .
          </P>
          <H2>CONTROLS FOR DO-NOT-TRACK FEATURES</H2>
          <P>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognising and
            implementing DNT signals has been finalised. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </P>
          <H2>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</H2>
          <P>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </P>
          <P>
            California Civil Code Section 1798.83, also known as the 'Shine The
            Light' law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </P>
          <P>
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </P>
          <H2>CCPA Privacy Notice</H2>
          <P>The California Code of Regulations defines a 'resident' as:</P>
          <P>
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </P>
          <P>
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </P>
          <P>All other individuals are defined as 'non-residents'.</P>
          <P>
            If this definition of 'resident' applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </P>
          <P>What categories of personal information do we collect?</P>
          <P>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </P>
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h3>How do we use and share your personal information?</h3>
          <P>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </P>
          <P>
            You may contact us by email at pwywijas@oakgames.it, or by referring
            to the contact details at the bottom of this document.
          </P>
          <P>
            If you are using an authorised agent to exercise your right to opt
            out we may deny a request if the authorised agent does not submit
            proof that they have been validly authorised to act on your behalf.
          </P>
          <h3>Will your information be shared with anyone else?</h3>
          <P>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </P>
          <P>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            'selling' of your personal information.
          </P>
          <P>
            Oakfusion has not disclosed or sold any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. Oakfusion will not sell personal information in
            the future belonging to website visitors, users, and other
            consumers.
          </P>
          <h3>Your rights with respect to your personal data</h3>
          <P>Right to request deletion of the data — Request to delete</P>
          <P>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </P>
          <P>Right to be informed — Request to know</P>
          <P>Depending on the circumstances, you have a right to know:</P>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>whether we sell your personal information to third parties;</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>
          <P>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </P>
          <P>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </P>
          <P>
            We will not discriminate against you if you exercise your privacy
            rights.
          </P>
          <P>Verification process</P>
          <P>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </P>
          <P>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </P>
          <P>Other privacy rights</P>
          <ul>
            <li>
               You may object to the processing of your personal information.
            </li>
            <li>
               You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
            <li>
               You can designate an authorised agent to make a request under
              the CCPA on your behalf. We may deny a request from an authorised
              agent that does not submit proof that they have been validly
              authorised to act on your behalf in accordance with the CCPA.
            </li>
            <li>
               You may request to opt out from future selling of your personal
              information to third parties. Upon receiving an opt-out request,
              we will act upon the request as soon as feasibly possible, but no
              later than fifteen (15) days from the date of the request
              submission.
            </li>
          </ul>
          <P>
            To exercise these rights, you can contact us by email at
            pwywijas@oakgames.it, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </P>
          <H2>DO WE MAKE UPDATES TO THIS NOTICE?</H2>
          <P>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </P>
          <P>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated 'Revised' date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </P>
          <H2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</H2>
          <P>
            If you have questions or comments about this notice, you may email
            us at pwywijas@oakgmes.it or by post to:
          </P>
          <P>Oakfusion</P>
          <P>Żmigrodzka 244</P>
          <P>Wrocław 51-131</P>
          <P>Poland</P>
          <H2>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </H2>
          <P>
            You have the right to request access to the personal information we
            collect from you, change that information, or delete it. To request
            to review, update, or delete your personal information, please
            submit a request form by clicking{" "}
            <a href="https://app.termly.io/notify/816eb576-d506-4352-8336-c36c1ee6873d">
              here
            </a>
            . This privacy policy was created using Termly's{" "}
            <a href="https://termly.io/products/privacy-policy-generator/">
              Privacy Policy Generator
            </a>
            .
          </P>
        </div>
      </Content>
    </Container>
  )
}
